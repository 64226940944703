import axios from 'axios';
class OrderService {
  //baseURL = 'http://localhost:8080/api/v1/admin'; // This is the local URL
  baseURL = 'https://bagagelocker-api.onrender.com/api/v1/admin'; // This is the production URL
  //baseURL = 'https://bagagelocker-cancel-order.onrender.com/api/v1/admin';
  async getOrders() {
    return await axios.get(this.baseURL + '/api-order/get')
  }
  async setOrderStatus(orderId, status) {
    return await axios.put(this.baseURL + '/api-order/set-status/' + orderId + '/' + status)
  }
}

export default new OrderService();