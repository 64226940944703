import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate"
import reservations from "./modules/reservations";
import users from "./modules/users";
import orders from "./modules/orders";
const store = createStore({
    modules: {
        reservations,
        users,
        orders
    },
    plugins: [createPersistedState()]
})
export default store