import axios from 'axios';
class ReservationService {
  //baseURL = 'http://localhost:8080/api/v1/admin'; // This is the local URL
  baseURL = 'https://bagagelocker-api.onrender.com/api/v1/admin'; // This is the production URL
  //baseURL = 'https://bagagelocker-cancel-order.onrender.com/api/v1/admin';
  async getReservations(page) {
    return await axios.get(this.baseURL + '/setting/getReservations/' + page);
  }
  async startReservation(reservationId) {
    return await axios.post(this.baseURL + '/setting/startReservation', { "id": reservationId });
  }
  async finishReservation(reservationId) {
    return await axios.post(this.baseURL + '/setting/finishReservation', { "id": reservationId });
  }
  async cancelReservation(reservationId) {
    return await axios.post(this.baseURL + '/setting/cancelReservation', { "id": reservationId });
  }
  async setReservationConfirmed(reservationId) {
    return await axios.post(this.baseURL + '/setting/setReservationConfirmed', { "id": reservationId });
  }
  async setReservationOngoing(reservationId) {
    return await axios.post(this.baseURL + '/setting/setReservationOngoing', { "id": reservationId });
  }
  async setReservationCheckin(reservationId) {
    return await axios.post(this.baseURL + '/setting/setReservationCheckin', { "id": reservationId });
  }
  async setReservationInprogress(reservationId) {
    return await axios.post(this.baseURL + '/setting/setReservationInprogress', { "id": reservationId });
  }
  async setReservationCheckout(reservationId) {
    return await axios.post(this.baseURL + '/setting/setReservationCheckout', { "id": reservationId });
  }
  async setReservationFinished(reservationId) {
    return await axios.post(this.baseURL + '/setting/setReservationFinished', { "id": reservationId });
  }
  async setReservationCancelled(reservationId) {
    return await axios.post(this.baseURL + '/setting/setReservationCancelled', { "id": reservationId });
  }
}

export default new ReservationService();