<template>
    <div class="fullscreen-div container">
        <ToastComponent></ToastComponent>
        <div class="row">
            <NavBar />
            <div class="flex">
                <button @click="refreshData" type="button" class="btn btn-success mr-5 mt-5">
                    Refresh
                </button><!--
                <button v-if="canSetOldReservationOngoing" type="button" class="btn btn-primary mr-5 mt-5"
                    data-toggle="modal" data-target="#oldReservationsToBeStartedModal">
                    Set old reservations ONGOING ({{ oldReservationsToBeStartedCount }})
                </button>
                <button v-else @click="setOldReservationsOngoing" type="button" class="btn btn-primary mr-5 mt-5"
                    disabled>
                    <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    Set old reservations ONGOING
                </button>
                <button v-if="canSetOldReservationFinished" type="button" class="btn btn-primary mr-5 mt-5"
                    data-toggle="modal" data-target="#oldReservationsToBeFinishedModal">
                    Set old reservations FINISHED ({{ oldReservationsToBeFinishedCount }})
                </button>
                <button v-else @click="setOldReservationsFinished" type="button" class="btn btn-primary mr-5 mt-5"
                    disabled>
                    <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    Set old reservations FINISHED
                </button>-->
            </div>
            <div class="flex"><!--
                <button v-if="canSetReservationOngoing" type="button" class="btn btn-primary mr-5 mt-5"
                    data-toggle="modal" data-target="#reservationsToBeStartedModal">
                    Set reservations ONGOING ({{ reservationsToBeStartedCount }})
                </button>
                <button v-else @click="setReservationsOngoing" type="button" class="btn btn-primary mr-5 mt-5" disabled>
                    <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    Set reservations ONGOING
                </button>
                <button v-if="canSetReservationFinished" type="button" class="btn btn-primary mr-5 mt-5"
                    data-toggle="modal" data-target="#reservationsToBeFinishedModal">
                    Set reservations FINISHED ({{ reservationsToBeFinishedCount }})
                </button>
                <button v-else @click="setReservationsFinished" type="button" class="btn btn-primary mr-5 mt-5"
                    disabled>
                    <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    Set reservations FINISHED
                </button>-->
            </div>
            <div class="flex">
                <button v-if="canSetTotalBoxCount" class="btn btn-primary mr-5 mt-5" data-toggle="modal"
                    data-target="#totalBoxCountModal">Set
                    Total Box Count ({{ totalBoxCount }})</button>
                <button v-else class="btn btn-primary mr-5 mt-5" data-toggle="modal" data-target="#totalBoxCountModal"
                    disabled><span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>Set
                    Total Box Count ({{ totalBoxCount }})</button>
                <button v-if="canSetPasscode" class="btn btn-primary mr-5 mt-5" data-toggle="modal"
                    data-target="#passcodeModal">Set
                    Passcode ({{ passcode }})</button>
                <button v-else class="btn btn-primary mr-5 mt-5" data-toggle="modal" data-target="#passcodeModal"
                    disabled><span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>Set
                    Passcode for TTLock ({{ passcode }})</button><!--
                <button v-if="orderCancellationActivated" class="btn btn-primary mr-5 mt-5" data-toggle="modal"
                    data-target="#orderCancellationDeactivationModal">Deactivate Order Cancellation</button>
                <button v-else class="btn btn-primary mr-5 mt-5" data-toggle="modal"
                    data-target="#orderCancellationActivationModal">Activate Order Cancellation</button>-->
            </div>
            <div class="flex">
                <button class="btn btn-secondary mt-5" data-toggle="modal" data-target="#emailTestModal">Email
                    Test</button>
            </div>
            <div class="flex">
                <button class="btn btn-secondary mt-5" data-toggle="modal"
                    data-target="#emailReservationConfirmedTestModal">Email Reservation Confirmed Test</button>
            </div>
            <div class="flex">
                <button class="btn btn-secondary mt-5" data-toggle="modal"
                    data-target="#emailReservationFinishedTestModal">Email Reservation Finished Test</button>
            </div>
            <div class="flex">
                <button class="btn btn-warning mt-5" data-toggle="modal" data-target="#fakeReservationModal">Create
                    Order</button>
            </div>
            <!-- Modal Total Box Count-->
            <div class="modal fade" id="totalBoxCountModal" tabindex="-1" role="dialog"
                aria-labelledby="totalBoxCountModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="totalBoxCountModalLabel">Total box count</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            Set the number of boxes available for reservations
                        </div>
                        <input type="number" class="form-control" v-model="totalBoxCountWritten" id="total-box-count">
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" data-dismiss="modal"
                                @click="setTotalBoxCount">Save changes</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal Activate Order Cancellation -->
            <div class="modal fade" id="orderCancellationActivationModal" tabindex="-1" role="dialog"
                aria-labelledby="orderCancellationActivationModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="orderCancellationActivationModalLabel">Order Cancellation
                                Feature</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            Activate order cancellation
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" data-dismiss="modal"
                                @click="activateOrderCancellation">Activate</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal Activate Order Cancellation -->
            <div class="modal fade" id="orderCancellationDeactivationModal" tabindex="-1" role="dialog"
                aria-labelledby="orderCancellationDeactivationModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="orderCancellationDeactivationModalLabel">Order Cancellation
                                Feature</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            Deactivate order cancellation
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" data-dismiss="modal"
                                @click="deactivateOrderCancellation">Deactivate</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Email Test Modal-->
            <div class="modal fade" id="emailTestModal" tabindex="-1" role="dialog"
                aria-labelledby="emailTestModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="emailTestModalLabel">Email Test</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            Send a test email to the specified email address
                        </div>
                        <input type="text" class="form-control" v-model="writtenTestEmail" id="emailTest">
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" data-dismiss="modal"
                                @click="sendEmailTest">Send Email Test</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Email Reservation Confirmed Test Modal-->
            <div class="modal fade" id="emailReservationConfirmedTestModal" tabindex="-1" role="dialog"
                aria-labelledby="emailReservationConfirmedTestModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="emailReservationConfirmedTestModalLabel">Email Reservation
                                Confirmed Test</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            Send a reservation confirmed email to the specified email address
                        </div>
                        <input type="text" class="form-control" v-model="writtenReservationConfirmedTestEmail"
                            id="emailTest">
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" data-dismiss="modal"
                                @click="sendReservationConfirmedEmailTest">Send Reservation Confirmed Email
                                Test</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="emailReservationFinishedTestModal" tabindex="-1" role="dialog"
                aria-labelledby="emailReservationFinishedTestModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="emailReservationFinishedTestModalLabel">Email Reservation
                                Finished Test</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            Send a reservation finished email to the specified email address
                        </div>
                        <input type="text" class="form-control" v-model="writtenReservationFinishedTestEmail"
                            id="emailTest">
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" data-dismiss="modal"
                                @click="sendReservationFinishedEmailTest">Send Reservation Finished Email Test</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal Passcode-->
            <div class="modal fade" id="passcodeModal" tabindex="-1" role="dialog" aria-labelledby="passcodeModalLabel"
                aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="passcodeModalLabel">Passcode</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            Set passcode for TTLock
                        </div>
                        <input type="text" class="form-control" v-model="writtenPasscode" id="passcode">
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" data-dismiss="modal" @click="setPasscode">Save
                                changes</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal fake reservation-->
            <div class="modal fade" id="fakeReservationModal" tabindex="-1" role="dialog"
                aria-labelledby="fakeReservationModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="fakeReservationModalLabel">Create Order</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <h6>Create an order</h6>
                            <label for="startDateCreatingOrder" class="mr-3">Start Date: </label>
                            <input type="date" v-model="startDate" class="custom-range mr-5" id="startDateCreatingOrder">
                            <label for="customRange3" class="mr-3">Duration: </label>
                            <input type="number" v-model="duration" class="custom-range" min="1" max="30" step="1"
                                id="customRange3">
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" data-dismiss="modal"
                                @click="createFakeReservation">Create order</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal Old Reservation to be finished-->
            <div class="modal fade" id="oldReservationsToBeFinishedModal" tabindex="-1" role="dialog"
                aria-labelledby="oldReservationsToBeFinishedModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="oldReservationsToBeFinishedModalLabel">Old Reservations To Be
                                Finished</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            Old reservations to be finished have their end date in the past (before today)<br>Their
                            status is ONGOING or CHECKIN or INPROGRESS or CHECKOUT
                        </div>
                        <div class="col-12 flex row text-small"
                            v-for="reservation in store.getters.oldReservationsToBeFinished" :key="reservation.id">
                            <ul>
                                <li>
                                    ID {{ reservation.id }} - Order ID {{ reservation.orderId }} - from {{
                    moment(reservation.startDate, "YYYY-MM-DD").format('LL') }} to {{
                    moment(reservation.endDate, "YYYY-MM-DD").format('LL') }}. {{ reservation.username
                                    }} - <b>{{ reservation.status }}</b>
                                </li>
                            </ul>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" data-dismiss="modal"
                                @click="setOldReservationsFinished">Update Reservations</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal Old Reservation to be Started-->
            <div class="modal fade" id="oldReservationsToBeStartedModal" tabindex="-1" role="dialog"
                aria-labelledby="oldReservationsToBeStartedModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="oldReservationsToBeStartedModalLabel">Old Reservations To Be
                                Started</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            Old reservations to be started have their start date in the past (before today)<br>Their
                            status is CONFIRMED
                        </div>
                        <div class="col-12 flex row text-small"
                            v-for="reservation in store.getters.oldReservationsToBeStarted" :key="reservation.id">
                            <ul>
                                <li>
                                    ID {{ reservation.id }} - Order ID {{ reservation.orderId }} - from {{
                    moment(reservation.startDate, "YYYY-MM-DD").format('LL') }} to {{
                    moment(reservation.endDate, "YYYY-MM-DD").format('LL') }}. {{ reservation.username
                                    }} - <b>{{ reservation.status }}</b>
                                </li>
                            </ul>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" data-dismiss="modal"
                                @click="setOldReservationsOngoing">Update Reservations</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal Reservation to be Started-->
            <div class="modal fade" id="reservationsToBeStartedModal" tabindex="-1" role="dialog"
                aria-labelledby="reservationsToBeStartedModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="reservationsToBeStartedModalLabel">Reservations To Be
                                Started</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            Reservations to be started have their start date today<br>Their
                            status is CONFIRMED
                        </div>
                        <div class="col-12 flex row text-small"
                            v-for="reservation in store.getters.reservationsToBeStarted" :key="reservation.id">
                            <ul>
                                <li>
                                    ID {{ reservation.id }} - Order ID {{ reservation.orderId }} - from {{
                    moment(reservation.startDate, "YYYY-MM-DD").format('LL') }} to {{
                    moment(reservation.endDate, "YYYY-MM-DD").format('LL') }}. {{ reservation.username
                                    }} - <b>{{ reservation.status }}</b>
                                </li>
                            </ul>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" data-dismiss="modal"
                                @click="setReservationsOngoing">Update Reservations</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal Reservation to be Finished-->
            <div class="modal fade" id="reservationsToBeFinishedModal" tabindex="-1" role="dialog"
                aria-labelledby="reservationsToBeFinishedModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="reservationsToBeFinishedModalLabel">Reservations To Be
                                Finished</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            Reservations to be finished have their end date today<br>Their
                            status is ONGOING or CHECKIN or INPROGRESS or CHECKOUT
                        </div>
                        <div class="col-12 flex row text-small"
                            v-for="reservation in store.getters.reservationsToBeFinished" :key="reservation.id">
                            <ul>
                                <li>
                                    ID {{ reservation.id }} - Order ID {{ reservation.orderId }} - from {{
                    moment(reservation.startDate, "YYYY-MM-DD").format('LL') }} to {{
                    moment(reservation.endDate, "YYYY-MM-DD").format('LL') }}. {{ reservation.username
                                    }} - <b>{{ reservation.status }}</b>
                                </li>
                            </ul>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary" data-dismiss="modal"
                                @click="setReservationsFinished">Update Reservations</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import moment from 'moment'
import SettingsService from '@/services/SettingsService.js'
import NavBar from '@/components/layouts/NavBar.vue';
import { useStore } from 'vuex'
import { useToast } from "primevue/usetoast";
import { ref, onMounted } from 'vue';
const toast = useToast();
const store = useStore()
let canSetOldReservationFinished = ref(true)
let canSetOldReservationOngoing = ref(true)
let canSetReservationOngoing = ref(true)
let canSetReservationFinished = ref(true)
let canSetTotalBoxCount = ref(true)
let canSetPasscode = ref(true)
let oldReservationsToBeFinishedCount = ref(store.getters.oldReservationsToBeFinishedCount)
let oldReservationsToBeStartedCount = ref(store.getters.oldReservationsToBeStartedCount)
let reservationsToBeStartedCount = ref(store.getters.reservationsToBeStartedCount)
let reservationsToBeFinishedCount = ref(store.getters.reservationsToBeFinishedCount)
let passcode = ref(store.getters.passcode)
let writtenPasscode = ref(null)
let writtenTestEmail = ref(null)
let writtenReservationConfirmedTestEmail = ref(null)
let writtenReservationFinishedTestEmail = ref(null)
let totalBoxCount = ref(store.getters.totalBoxCount)
let totalBoxCountWritten = ref("0")
let duration = ref(1)
let startDate = ref(null)
//let orderCancellationActivated = ref(false)
import axios from 'axios';
const baseURL = 'https://bagagelocker-api.onrender.com/api/v1/admin';
//const baseURL = 'http://localhost:8080/api/v1/admin';
const sendEmailTest = () => {
    SettingsService.sendEmailTest(writtenTestEmail.value)
    toast.add({ severity: "info", summary: "Info", detail: "Email sent to " + writtenTestEmail.value });
}
const sendReservationConfirmedEmailTest = () => {
    SettingsService.sendReservationConfirmedEmailTest(writtenReservationConfirmedTestEmail.value)
    toast.add({ severity: "info", summary: "Info", detail: "Email sent to " + writtenReservationConfirmedTestEmail.value });
}
const sendReservationFinishedEmailTest = () => {
    SettingsService.sendReservationFinishedEmailTest(writtenReservationFinishedTestEmail.value)
    toast.add({ severity: "info", summary: "Info", detail: "Email sent to " + writtenReservationFinishedTestEmail.value });
}
const activateOrderCancellation = () => {
    SettingsService.activateOrderCancellation()
    toast.add({ severity: "success", summary: "Order Cancellation activated", detail: "Order Cancellation activated" });
}
const deactivateOrderCancellation = () => {
    SettingsService.deactivateOrderCancellation()
    toast.add({ severity: "success", summary: "Order Cancellation deactivated", detail: "Order Cancellation deactivated" });
}

const createFakeReservation = () => {
    SettingsService.createFakeReservation(startDate.value, duration.value).then(response => {
        if (response.data) {
            if (response.data == "startDate error") {
                toast.add({ severity: "error", summary: "Error", detail: "Start date should not be earlier than today. " });
                return
            } else {
                toast.add({ severity: "success", summary: "Order created successfully!", detail: "Username: bagagelocker, Code: " + response.data });
                return
            }
        } else {
            toast.add({ severity: "error", summary: "Error", detail: "An error occurred. " });
        }
    })
}
const refreshData = () => {
    fetchOldReservationsToBeFinished()
    fetchOldReservationsToBeStarted()
    fetchReservationsToBeStarted()
    fetchReservationsToBeFinished()
    fetchPasscode()
    fetchTotalBoxCount()
    //fetchOrderCancellationActivated()
}
/*
const fetchOrderCancellationActivated = () => {
    SettingsService.getOrderCancellation().then(response => {
        if (response.data === 0) {
            orderCancellationActivated.value = false
        } else {
            orderCancellationActivated.value = true
        }
    });
}
*/
const fetchOldReservationsToBeFinished = () => {
    canSetOldReservationFinished.value = false;
    axios.get(baseURL + '/setting/getOldReservationsToBeFinished').then(response => {
        store.commit('setOldReservationsToBeFinished', response.data.reservationList);
        store.commit('setOldReservationsToBeFinishedCount', response.data.reservationList.length);
        oldReservationsToBeFinishedCount.value = response.data.reservationList.length;
        canSetOldReservationFinished.value = true;
    });
}
const fetchOldReservationsToBeStarted = () => {
    canSetOldReservationOngoing.value = false;
    axios.get(baseURL + '/setting/getOldReservationsToBeStarted').then(response => {
        store.commit('setOldReservationsToBeStarted', response.data.reservationList);
        store.commit('setOldReservationsToBeStartedCount', response.data.reservationList.length);
        oldReservationsToBeStartedCount.value = response.data.reservationList.length;
        canSetOldReservationOngoing.value = true;
    });
}
const fetchReservationsToBeStarted = () => {
    canSetReservationOngoing.value = false;
    axios.get(baseURL + '/setting/getReservationsToBeStarted').then(response => {
        store.commit('setReservationsToBeStarted', response.data.reservationList);
        store.commit('setReservationsToBeStartedCount', response.data.reservationList.length);
        reservationsToBeStartedCount.value = response.data.reservationList.length;
        canSetReservationOngoing.value = true;
    });
}
const fetchReservationsToBeFinished = () => {
    canSetReservationFinished.value = false;
    axios.get(baseURL + '/setting/getReservationsToBeFinished').then(response => {
        store.commit('setReservationsToBeFinished', response.data.reservationList);
        store.commit('setReservationsToBeFinishedCount', response.data.reservationList.length);
        reservationsToBeFinishedCount.value = response.data.reservationList.length;
        canSetReservationFinished.value = true;
    });
}
const fetchTotalBoxCount = () => {
    canSetTotalBoxCount.value = false;
    SettingsService.getTotalBoxCount().then(response => {
        store.commit('setTotalBoxCount', response.data);
        totalBoxCount.value = response.data;
        canSetTotalBoxCount.value = true;
    });
}
const fetchPasscode = () => {
    canSetPasscode.value = false;
    SettingsService.getPasscode().then(response => {
        store.commit('setPasscode', response.data);
        passcode.value = response.data;
        canSetPasscode.value = true;
    });
}
onMounted(() => {
    /*canSetOldReservationFinished.value = false
    canSetOldReservationOngoing.value = false
    canSetReservationOngoing.value = false
    canSetReservationFinished.value = false
    store.dispatch('fetchOldReservationsToBeFinished')
    store.dispatch('fetchOldReservationsToBeStarted')
    store.dispatch('fetchReservationsToBeStarted')
    store.dispatch('fetchReservationsToBeFinished')
    
    SettingsService.getOldReservationsToBeStarted().then(response => {
        canSetOldReservationOngoing.value = true
        if(response.data.errors) {
            toast.add({ severity: "error", summary: "Error", detail: "An error occurred. " + response.errors });
            return
        }
        oldReservationsToBeStarted.value = response.data.reservationList
        oldReservationsToBeStartedCount.value = response.data.reservationList.length
        console.log("oldReservationsToBeStarted.value="+oldReservationsToBeStarted.value);
    })
    SettingsService.getReservationsToBeStarted().then(response => {
        canSetReservationOngoing.value = true
        if(response.data.errors) {
            toast.add({ severity: "error", summary: "Error", detail: "An error occurred. " + response.errors });
            return
        }
        reservationsToBeStarted.value = response.data.reservationList
        reservationsToBeStartedCount.value = response.data.reservationList.length
        console.log("reservationsToBeStarted.value="+reservationsToBeStarted.value);
    })
    SettingsService.getReservationsToBeFinished().then(response => {
        canSetReservationFinished.value = true
        if(response.data.errors) {
            toast.add({ severity: "error", summary: "Error", detail: "An error occurred. " + response.errors });
            return
        }
        reservationsToBeFinished.value = response.data.reservationList
        reservationsToBeFinishedCount.value = response.data.reservationList.length
        console.log("reservationsToBeFinished.value="+reservationsToBeFinished.value);
    })
    */
})
const setTotalBoxCount = () => {
    SettingsService.setTotalBoxCount(totalBoxCountWritten.value)
    store.commit('setTotalBoxCount', totalBoxCountWritten)
    totalBoxCount.value = totalBoxCountWritten.value
}
const setPasscode = () => {
    SettingsService.setPasscode(writtenPasscode.value)
    store.commit('setPasscode', writtenPasscode)
    passcode.value = writtenPasscode.value
}
const setReservationsOngoing = () => {
    canSetReservationOngoing.value = false
    SettingsService.setReservationsOngoing().then((response) => {
        canSetReservationOngoing.value = true
        let failCount = 0
        if (response.data.errors) {
            failCount = response.data.errors.length
        }
        let successCount = 0
        let reservationsCount = 0
        if (response.data.reservationList) {
            reservationsCount = response.data.reservationList.length
            successCount = reservationsCount - failCount
        }
        if (reservationsCount === 0) {
            toast.add({ severity: "success", summary: "Info", detail: "No reservations to set Ongoing" });
        } else {
            if (successCount === 0) {
                toast.add({ severity: "error", summary: "Error", detail: failCount + " reservations failed to set Ongoing. " + response.errors });
            } else {
                toast.add({ severity: "info", summary: "Success", detail: successCount + " reservations successfully set as Ongoing. " + failCount + " reservations failed." });
            }
        }
    });
}
const setReservationsFinished = () => {
    canSetReservationFinished.value = false
    SettingsService.setReservationsFinished().then((response) => {
        canSetReservationFinished.value = true
        let failCount = 0
        if (response.data.errors) {
            failCount = response.data.errors.length
        }
        let successCount = 0
        let reservationsCount = 0
        if (response.data.reservationList) {
            reservationsCount = response.data.reservationList.length
            successCount = reservationsCount - failCount
        }
        if (reservationsCount === 0) {
            toast.add({ severity: "success", summary: "Info", detail: "No reservations to set Finished" });
        } else {
            if (successCount === 0) {
                toast.add({ severity: "error", summary: "Error", detail: failCount + " reservations failed to set Finished. " + response.errors });
            } else {
                toast.add({ severity: "info", summary: "Success", detail: successCount + " reservations successfully set as Finished. " + failCount + " reservations failed." });
            }
        }
    });
}
const setOldReservationsOngoing = () => {
    canSetOldReservationOngoing.value = false
    SettingsService.setOldReservationsOngoing().then((response) => {
        canSetOldReservationOngoing.value = true
        let failCount = 0
        if (response.data.errors) {
            failCount = response.data.errors.length
        }
        let successCount = 0
        let reservationsCount = 0
        if (response.data.reservationList) {
            reservationsCount = response.data.reservationList.length
            successCount = reservationsCount - failCount
        }
        console.log("reservationsCount=" + reservationsCount);
        if (reservationsCount === 0) {
            toast.add({ severity: "success", summary: "Info", detail: "No old reservations to set Ongoing" });
        } else {
            if (successCount === 0) {
                toast.add({ severity: "error", summary: "Error", detail: failCount + " reservations failed to set Ongoing. " + response.errors });
            } else {
                toast.add({ severity: "info", summary: "Success", detail: successCount + " reservations successfully set as Ongoing. " + failCount + " reservations failed." });
            }
        }
    });
}
async function setOldReservationsFinished() {
    canSetOldReservationFinished.value = false
    SettingsService.setOldReservationsFinished().then((response) => {
        canSetOldReservationFinished.value = true
        console.log("response=" + response.data.reservationsCount);
        console.log("response.errors=" + response.data.errors);
        console.log("response.reservationsList=" + response.data.reservationList);
        console.log("response.reservationsCount=" + response.data.reservationsCount);
        let failCount = 0
        console.log("response.data.errors= " + response.data.errors);
        if (response.data.errors) {
            failCount = response.data.errors.length
        }
        let successCount = 0
        let reservationsCount = 0
        if (response.data.reservationList) {
            reservationsCount = response.data.reservationList.length
            successCount = reservationsCount - failCount
        }
        if (reservationsCount === 0) {
            toast.add({ severity: "success", summary: "Info", detail: "No old reservations to set Finished" });
        } else {
            if (successCount === 0) {
                toast.add({ severity: "error", summary: "Error", detail: failCount + " reservations failed to set Finished. " + response.errors });
            } else {
                toast.add({ severity: "info", summary: "Success", detail: successCount + " reservations successfully set as Finished. " + failCount + " reservations failed." });
            }
        }
    });
}
</script>
<style scoped>
.fullscreen-div {
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 400px;
    margin-top: 50px;
}

.navbar-top-margin {
    position: relative;
    margin-top: -200px;

}

.margin-top-container {
    margin-top: 300px;
}

.text-small {
    font-size: 14px;
}
</style>