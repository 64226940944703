<template>
    <div class="container-fluid text-center mt-5 mb-5">
        <NavBar />
        <ToastComponent></ToastComponent>
        <p class="fw-bolder text-success margin-top-navbar">Orders List</p>
        <div class="flex row">
            <div class="row">
                <div class="col-11"></div>
                <button @click="fetchOrders" type="button" class="btn btn-success float-right col-1 mb-2">
                    Refresh
                </button>
            </div>
            <div v-if="notRefreshing">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Order ID</th>
                            <th scope="col">App ID</th>
                            <th scope="col">Device Number</th>
                            <th scope="col">User ID</th>
                            <th scope="col">Status</th>
                            <th scope="col">Box ID</th>
                            <th scope="col">Start Date</th>
                            <th scope="col">End Date</th>
                            <th scope="col">CTime</th>
                            <th scope="col">UPTime</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="order in orders" :key="order.order_id">
                            <th scope="row">{{ order.order_id }}</th>
                            <td>{{ order.app_id }}</td>
                            <td>{{ order.device_number }}</td>
                            <td>{{ order.user_id }}</td>
                            <td>
                                <div class="btn-group" style="margin-top: -6px;">
                                    <button type="button" class="btn btn-primary btn-sm">{{ order.status
                                        }}</button>
                                    <button type="button"
                                        class="btn btn-primary btn-sm dropdown-toggle dropdown-toggle-split"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span class="sr-only">Toggle Dropdown</span>
                                    </button>
                                    <div class="dropdown-menu">
                                        <a class="dropdown-item" @click="setOrderConfirmed(order)">CONFIRMED</a>
                                        <a class="dropdown-item" @click="setOrderOngoing(order)">ONGOING</a>
                                        <a class="dropdown-item" @click="setOrderCheckin(order)">CHECKIN</a>
                                        <a class="dropdown-item" @click="setOrderInprogress(order)">INPROGRESS</a>
                                        <a class="dropdown-item" @click="setOrderCheckout(order)">CHECKOUT</a>
                                        <a class="dropdown-item" @click="setOrderFinished(order)">FINISHED</a>
                                        <div class="dropdown-divider"></div>
                                        <a class="dropdown-item" @click="setOrderCancelled(order)">CANCELLED</a>
                                    </div>
                                </div>
                            </td>
                            <td>{{ order.box_id }}</td>
                            <td>{{ order.start_date }}</td>
                            <td>{{ order.end_date }}</td>
                            <td>{{ order.ctime }}</td>
                            <td>{{ order.uptime }}</td>
                            <!--<td>
                                <div class="btn-group" style="margin-top: -6px;">
                                    <button type="button" class="btn btn-success btn-sm mr-1"
                                        @click="startReservation(reservation)"
                                        :disabled="reservation.status == 'ONGOING'">Start</button>
                                    <button type="button" class="btn btn-primary btn-sm mr-1"
                                        @click="finishReservation(reservation)"
                                        :disabled="reservation.status == 'FINISHED'">Finish</button>
                                    <button type="button" class="btn btn-warning btn-sm mr-1"
                                        @click="cancelReservation(reservation)"
                                        :disabled="reservation.status == 'CANCELLED'">Cancel</button>
                                    <button type="button" class="btn btn-secondary btn-sm"
                                        @click="getCode(reservation)">Code</button>
                                </div>
                            </td>-->
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-else>
                <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Refreshing...</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import NavBar from '@/components/layouts/NavBar.vue';
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useToast } from "primevue/usetoast";
import OrdersService from '@/services/OrderService'
const toast = useToast()
const store = useStore()
let orders = ref(store.getters.orders)
let notRefreshing = ref(true)
const fetchOrders = () => {
    toast.add({ severity: "info", summary: "Refreshing", detail: "Orders list is refreshing...", life: 2500 });
    notRefreshing.value = false
    OrdersService.getOrders().then((response) => {
        console.log(response.data);
        notRefreshing.value = true
        if (response === null) {
            toast.add({ severity: "error", summary: "Error", detail: "An error occurred. " });
            return
        }
        store.commit('setOrders', response.data)
        orders.value = response.data
    })
}
const setOrderConfirmed = (order) => {
    setOrderStatus(order, "CONFIRMED")
}
const setOrderOngoing = (order) => {
    setOrderStatus(order, "ONGOING")
}
const setOrderCheckin = (order) => {
    setOrderStatus(order, "CHECKIN")
}
const setOrderInprogress = (order) => {
    setOrderStatus(order, "INPROGRESS")
}
const setOrderCheckout = (order) => {
    setOrderStatus(order, "CHECKOUT")
}
const setOrderFinished = (order) => {
    setOrderStatus(order, "FINISHED")
}
const setOrderCancelled = (order) => {
    setOrderStatus(order, "CANCELLED")
}
const setOrderStatus = (order, status) => {
    OrdersService.setOrderStatus(order.order_id, status).then((response) => {
        if (response === null) {
            toast.add({ severity: "error", summary: "Error", detail: "An error occurred. " });
            return
        }
        fetchOrders()
    })
}
</script>
<style scoped></style>