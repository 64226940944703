const state = {
    orders: null
};

const getters = {
    orders: state => state.orders,
};

const actions = {
    
};

const mutations = {
    setOrders: (state, orders) => (state.orders = orders)
};

export default {
    state,
    getters,
    actions,
    mutations
};

