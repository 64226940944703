<template>
  <div class="container-fluid text-center mt-5 mb-5">
    <NavBar />
    <ToastComponent></ToastComponent>
    <p class="fw-bolder text-success margin-top-navbar">Reservations List</p>
    <div class="flex row">
      <div class="row">
        <div class="col-11"></div>
        <button @click="fetchReservations" type="button" class="btn btn-success float-right col-1 mb-2">
          Refresh
        </button>
      </div>
      <div v-if="notRefreshing">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Order ID</th>
              <th scope="col">Start Date</th>
              <th scope="col">End Date</th>
              <th scope="col">Username</th>
              <th scope="col">Status</th>
              <th scope="col">Creation Date</th>
              <th scope="col">Update Date</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="reservation in reservations" :key="reservation.id">
              <th scope="row">{{ reservation.id }}</th>
              <td>{{ reservation.orderId }}</td>
              <td>{{ reservation.startDate }}</td>
              <td>{{ reservation.endDate }}</td>
              <td>{{ reservation.username }}</td>
              <td>
                <div class="btn-group" style="margin-top: -6px;">
                  <button type="button" class="btn btn-primary btn-sm">{{ reservation.status }}</button>
                  <button type="button" class="btn btn-primary btn-sm dropdown-toggle dropdown-toggle-split"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="sr-only">Toggle Dropdown</span>
                  </button>
                  <div class="dropdown-menu">
                    <a class="dropdown-item" @click="setReservationConfirmed(reservation)">CONFIRMED</a>
                    <a class="dropdown-item" @click="setReservationOngoing(reservation)">ONGOING</a>
                    <a class="dropdown-item" @click="setReservationCheckin(reservation)">CHECKIN</a>
                    <a class="dropdown-item" @click="setReservationInprogress(reservation)">INPROGRESS</a>
                    <a class="dropdown-item" @click="setReservationCheckout(reservation)">CHECKOUT</a>
                    <a class="dropdown-item" @click="setReservationFinished(reservation)">FINISHED</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" @click="setReservationCancelled(reservation)">CANCELLED</a>
                  </div>
                </div>
              </td>
              <td>{{ reservation.creationDate }}</td>
              <td>{{ reservation.updateDate }}</td>
              <td>
                <div class="btn-group" style="margin-top: -6px;">
                  <button type="button" class="btn btn-success btn-sm mr-1" @click="startReservation(reservation)"
                    :disabled="reservation.status == 'ONGOING'">Start</button>
                  <button type="button" class="btn btn-primary btn-sm mr-1" @click="finishReservation(reservation)"
                    :disabled="reservation.status == 'FINISHED'">Finish</button>
                  <button type="button" class="btn btn-warning btn-sm mr-1" @click="cancelReservation(reservation)"
                    :disabled="reservation.status == 'CANCELLED'">Cancel</button>
                  <button type="button" class="btn btn-secondary btn-sm" @click="getCode(reservation)">Code</button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
            <li class="page-item disabled" v-if="page == 0">
              <a class="page-link" tabindex="-1">Previous</a>
            </li>
            <li class="page-item" v-else>
              <a type="button" class="page-link" @click="previousPage" tabindex="-1">Previous</a>
            </li>
            <li v-if="page == 0" class="page-item disabled"><a class="page-link">1</a></li>
            <li v-else class="page-item"><a type="button" class="page-link" @click="page1">1</a></li>
            <li v-if="page == 1" class="page-item disabled"><a class="page-link">2</a></li>
            <li v-else class="page-item"><a type="button" class="page-link" @click="page2">2</a></li>
            <li v-if="page == 2" class="page-item disabled"><a class="page-link">3</a></li>
            <li v-else class="page-item"><a type="button" class="page-link" @click="page3">3</a></li>
            <li v-if="page == 3" class="page-item disabled"><a class="page-link">4</a></li>
            <li v-else class="page-item"><a type="button" class="page-link" @click="page4">4</a></li>
            <li v-if="page == 4" class="page-item disabled"><a class="page-link">5</a></li>
            <li v-else class="page-item"><a type="button" class="page-link" @click="page5">5</a></li>
            <li class="page-item"><a type="button" class="page-link" @click="pageAll">All</a></li>
            <li class="page-item" v-if="page != 4">
              <a type="button" class="page-link" @click="nextPage">Next</a>
            </li>
            <li class="page-item disabled" v-else>
              <a type="button" class="page-link" @click="nextPage">Next</a>
            </li>
          </ul>
        </nav>
      </div>
      <div v-else>
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Refreshing...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import NavBar from '@/components/layouts/NavBar.vue';
import ReservationService from '../services/ReservationService'
import { useToast } from "primevue/usetoast";
import { useStore } from 'vuex';
import { ref, onMounted } from 'vue';
const toast = useToast();
const store = useStore()
let notRefreshing = ref(true)
let reservations = ref(store.getters.reservations)
let page = ref(0)
const nextPage = () => {
  page.value = page.value + 1
  fetchReservationsPage(page.value)
  console.log(page.value)
}
const previousPage = () => {
  if (page.value > 0) {
    page.value = page.value -1
    fetchReservationsPage(page.value)
  }
  console.log(page.value)
}
const page1 = () => {
  page.value = 0
  fetchReservationsPage(page.value)
  console.log(page.value)
}

const page2 = () => {
  page.value = 1
  fetchReservationsPage(page.value)
  console.log(page.value)
}

const page3 = () => {
  page.value = 2
  fetchReservationsPage(page.value)
  console.log(page.value)
}

const page4 = () => {
  page.value = 3
  fetchReservationsPage(page.value)
  console.log(page.value)
}

const page5 = () => {
  page.value = 4
  fetchReservationsPage(page.value)
  console.log(page.value)
}

const pageAll = () => {
  page.value = -1
  fetchReservationsPage(page.value)
  console.log(page.value)
}

const setReservationConfirmed = (reservation) => {
  console.log("setReservationConfirmed");
  notRefreshing.value = false
  ReservationService.setReservationConfirmed(reservation.id).then((response) => {
    console.log("response=" + response.data);
    notRefreshing.value = true
    if (response.data.errors) {
      toast.add({ severity: "error", summary: "Error", detail: "An error occurred. " + response.errors });
      return
    }
    toast.add({ severity: "success", summary: "Success", detail: "Reservation " + reservation.id + " order " + reservation.orderId + " confirmed successfully." });
    fetchReservations()
  })
}
const getCode = (reservation) => {
  toast.add({ severity: "info", summary: "Code", detail: "Reservation " + reservation.id + " order " + reservation.orderId + " code is " + reservation.code, life: 5000 });
}
const setReservationOngoing = (reservation) => {
  console.log("setReservationOngoing");
  notRefreshing.value = false
  ReservationService.setReservationOngoing(reservation.id).then((response) => {
    console.log("response=" + response.data);
    notRefreshing.value = true
    if (response.data.errors) {
      toast.add({ severity: "error", summary: "Error", detail: "An error occurred. " + response.errors });
      return
    }
    toast.add({ severity: "success", summary: "Success", detail: "Reservation " + reservation.id + " order " + reservation.orderId + " ongoing successfully. No email has been sent!" });
    fetchReservations()
  })
}
const setReservationCheckin = (reservation) => {
  console.log("setReservationCheckin");
  notRefreshing.value = false
  ReservationService.setReservationCheckin(reservation.id).then((response) => {
    console.log("response=" + response.data);
    notRefreshing.value = true
    if (response.data.errors) {
      toast.add({ severity: "error", summary: "Error", detail: "An error occurred. " + response.errors });
      return
    }
    toast.add({ severity: "success", summary: "Success", detail: "Reservation " + reservation.id + " order " + reservation.orderId + " checkin successfully." });
    fetchReservations()
  })
}
const setReservationInprogress = (reservation) => {
  console.log("setReservationInprogress");
  notRefreshing.value = false
  ReservationService.setReservationInprogress(reservation.id).then((response) => {
    console.log("response=" + response.data);
    notRefreshing.value = true
    if (response.data.errors) {
      toast.add({ severity: "error", summary: "Error", detail: "An error occurred. " + response.errors });
      return
    }
    toast.add({ severity: "success", summary: "Success", detail: "Reservation " + reservation.id + " order " + reservation.orderId + " in progress successfully." });
    fetchReservations()
  })
}
const setReservationCheckout = (reservation) => {
  console.log("setReservationCheckout");
  notRefreshing.value = false
  ReservationService.setReservationCheckout(reservation.id).then((response) => {
    console.log("response=" + response.data);
    notRefreshing.value = true
    if (response.data.errors) {
      toast.add({ severity: "error", summary: "Error", detail: "An error occurred. " + response.errors });
      return
    }
    toast.add({ severity: "success", summary: "Success", detail: "Reservation " + reservation.id + " order " + reservation.orderId + " checkout successfully." });
    fetchReservations()
  })
}
const setReservationFinished = (reservation) => {
  console.log("setReservationFinished");
  notRefreshing.value = false
  ReservationService.setReservationFinished(reservation.id).then((response) => {
    console.log("response=" + response.data);
    notRefreshing.value = true
    if (response.data.errors) {
      toast.add({ severity: "error", summary: "Error", detail: "An error occurred. " + response.errors });
      return
    }
    toast.add({ severity: "success", summary: "Success", detail: "Reservation " + reservation.id + " order " + reservation.orderId + " finished successfully. No email has been sent!" });
    fetchReservations()
  })
}
const setReservationCancelled = (reservation) => {
  console.log("setReservationCancelled");
  notRefreshing.value = false
  ReservationService.setReservationCancelled(reservation.id).then((response) => {
    console.log("response=" + response.data);
    notRefreshing.value = true
    if (response.data.errors) {
      toast.add({ severity: "error", summary: "Error", detail: "An error occurred. " + response.errors });
      return
    }
    toast.add({ severity: "success", summary: "Success", detail: "Reservation " + reservation.id + " order " + reservation.orderId + " cancelled successfully. No email has been sent!" });
    fetchReservations()
  })
}
const startReservation = (reservation) => {
  console.log("startReservation");
  notRefreshing.value = false
  ReservationService.startReservation(reservation.id).then((response) => {
    console.log("response=" + response.data.error);
    notRefreshing.value = true
    if (response.data.error) {
      toast.add({ severity: "error", summary: "Error", detail: response.data.error });
      return
    }
    toast.add({ severity: "success", summary: "Success", detail: "Reservation " + reservation.id + " order " + reservation.orderId + " started successfully. Ongoing email has been sent!" });
    fetchReservations()
  })
}
const finishReservation = (reservation) => {
  console.log("finishReservation");
  notRefreshing.value = false
  ReservationService.finishReservation(reservation.id).then((response) => {
    console.log("response=" + response.data.error);
    notRefreshing.value = true
    if (response.data.error) {
      toast.add({ severity: "error", summary: "Error", detail: response.data.error });
      return
    }
    toast.add({ severity: "success", summary: "Success", detail: "Reservation " + reservation.id + " order " + reservation.orderId + " finished successfully. Finished email has been sent!" });
    fetchReservations()
  })
}
const cancelReservation = (reservation) => {
  console.log("cancelReservation");
  notRefreshing.value = false
  ReservationService.cancelReservation(reservation.id).then((response) => {
    console.log("response=" + response.data);
    notRefreshing.value = true
    if (response.data.errors) {
      toast.add({ severity: "error", summary: "Error", detail: "An error occurred. " + response.errors });
      return
    }
    toast.add({ severity: "success", summary: "Success", detail: "Reservation " + reservation.id + " order " + reservation.orderId + " cancelled successfully. Cancelled email has been sent!" });
    fetchReservations()
  })
}
const fetchReservationsPage = (pageNumber) => {
  notRefreshing.value = false
  ReservationService.getReservations(pageNumber).then((response) => {
    notRefreshing.value = true
    if (response.data.errors) {
      toast.add({ severity: "error", summary: "Error", detail: "An error occurred. " + response.errors });
      return
    }
    store.commit('setReservations', response.data.reservationList)
    reservations.value = response.data.reservationList
  })
}
const fetchReservations = () => {
  toast.add({ severity: "info", summary: "Refreshing", detail: "Reservations list is refreshing...", life: 2500 });
  notRefreshing.value = false
  ReservationService.getReservations(page.value).then((response) => {
    notRefreshing.value = true
    if (response.data.errors) {
      toast.add({ severity: "error", summary: "Error", detail: "An error occurred. " + response.errors });
      return
    }
    store.commit('setReservations', response.data.reservationList)
    reservations.value = response.data.reservationList
  })
}
onMounted(() => {
  console.log("onMounted");
  //reservations.value = ref(store.getters.reservations)
})
</script>
<style scoped>
.font-size-dates {
  font-size: 12px;
}

.font-size-status {
  font-size: 14px;
}

.margin-top-navbar {
  margin-top: 70px;
  font-size: 40px;
}
</style>